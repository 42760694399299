import '../css/App.css';
import Header from '../components/header';
import Banner from '../components/banner';
import React, { useState, useEffect, useRef } from 'react';
import Footer from '../components/footer'
import Prices from '../components/prices'
import InfoBox from '../components/infoBox'
import ImgSelector from '../components/imgSelector';
import Discount from '../components/discount';

function CreateOffer({ title }) {

    document.title = title

    const [Time, setTime] = useState(0);

    const [discountCode, setDiscountCode] = useState('')
    const [discount, setDiscount] = useState(undefined)
    const [discountError, setDiscoutError] = useState('')

    const [pricing, setPricing] = useState()
    const [price, setPrice] = useState(0)
    const [discountAmount, setDiscountAmount] = useState(0)
    const [serviceFee, setServiceFee] = useState(0)
    const [total, setTotal] = useState(0)

    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [image, setImage] = useState(undefined)

    //const [userID, setUserID] = useState(NaN);
    const maxSize = 600

    const [infoBoxMessage, setInfoBoxMessage] = useState('');
    const [success, setSuccess] = useState(false);

    function timeout(delay) {
        return new Promise(res => setTimeout(res, delay));
    }

    async function informationBox(obj, message, success, delay = 2000) {
        const infoBoxObj = obj
        infoBoxObj.style.display = 'flex'

        setSuccess(success)
        setInfoBoxMessage(message)

        infoBoxObj.style.display = 'flex'

        await timeout(delay);
        infoBoxObj.style.opacity = 0
        await timeout(500);
        infoBoxObj.style.zIndex = -100
        await timeout(500);
        infoBoxObj.style.display = 'none'
    }


    async function successBox() {
        const queryString = window.location.search;
        const success = queryString.includes('success')
        const failure = queryString.includes('failure')

        const infoBoxObj = document.getElementById('infoBox')

        if (success || failure) {
            let message = 'Kuulutus edukalt loodud!'
            if (failure) {
                message = 'Midagi läks valesti!'
            }
            informationBox(infoBoxObj, message, success, 2000)
            if (success) {
                await timeout(2500)
                window.location.href = '/Profile'
            }
        }
    }

    useEffect(() => {
        async function fetchData() {
            const response = await fetch("/api/userInfo", {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            });
            var data = await response.json()
            //setUserID(data.id)
            setPhone(data.phone)
            setAddress(data.address)
            setCity(data.city)
        }
        fetchData()
        successBox()
    }, []);

    useEffect(() => {
        function setPrices(time) {
            function linearPrice(time, k) {
                return time * k
            }

            function powerSeriesPrice(time, k, p) {
                return k * (time ** p)
            }

            function getDiscount(price, type, amount) {
                if (type == 1) {
                    return amount
                }
                if (type == 0.01) {
                    return price * amount
                }
                return 0
            }

            function round(price) {
                return Math.round(price)
            }

            const basePrice = linearPrice(time, pricing.basePrice.k)
            const addedPrice = powerSeriesPrice(time, pricing.addedPrice.k, pricing.addedPrice.p)
            const advertisedPrice = linearPrice(time, pricing.advertisedPrice.k)
            let disc = 0
            let price = basePrice + addedPrice
            if (discount != undefined) {
                disc = getDiscount(price, discount.type, discount.amount)
            }

            price -= disc

            if (round(price) > 0 && round(price) < 50) {
                setPrice(round(basePrice))
                setServiceFee(round(addedPrice) + (50 - round(price)))
                setDiscountAmount(round(disc))
                setTotal(50)
                return
            }

            setPrice(round(basePrice))
            setServiceFee(round(addedPrice))
            setDiscountAmount(round(disc))
            setTotal(round(price))

            if (round(price) <= 0) {
                setTotal(0)
                return
            }
        }
        async function fetchPricing() {

            const response = await fetch("/api/pricing", {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            });
            var data = await response.json()
            setPricing(data)
        }

        if (pricing == undefined) {
            fetchPricing()
            return
        }
        if (Number.isNaN(Time)) {
            setPrices(0)
            return
        }
        setPrices(Time)
    }, [Time, discount])

    useEffect(() => {
        if (discountCode == '' || discountCode == undefined) {
            setDiscount(undefined)
            return
        }
        async function fetchDiscount() {
            const response = await fetch(`/api/discount?disc=${discountCode}`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            });
            if (response.ok) {
                var data = await response.json()
                setDiscount(data)
            }
            else {
                setDiscoutError('Kontrollige kood üle!')
            }
        }
        fetchDiscount()
    }, [discountCode])

    function getTimeInMinutes(time) {
        var times = time.split(':')
        return (parseInt(times[0]) * 60) + parseInt(times[1])
    }

    function timeChange(e) {
        let startTime = document.getElementById('startTime').value
        let endTime = document.getElementById('endTime').value

        let Time = Math.abs(getTimeInMinutes(startTime) - getTimeInMinutes(endTime))

        if (Number.isNaN(Time)) {
            return
        }

        if (startTime > endTime) {
            setEndTime(startTime)
            setStartTime(endTime)
        } else {
            setStartTime(startTime)
            setEndTime(endTime)
        }

        setTime(Time)
    }

    function phoneChange(e) {
        var regex = /[0-9 +]/g;
        var match = e.target.value.match(regex)
        if (match != null && match.length > 0) {
            e.target.value = match.join('')
            regex = /[0-9+]{1,4}|\d{1,4}/g
            match = e.target.value.match(regex)
            if (match != null && match.length > 0) e.target.value = match.join(' ')
        }
        else {
            e.target.value = ''
        }
        setPhone(e.target.value)
    }

    const createOfferPost = async (e) => {
        e.preventDefault();

        let name = document.getElementById('dogName').value
        let phone = document.getElementById('phone').value
        let date = document.getElementById('date').value
        let startTime = document.getElementById('startTime').value
        let endTime = document.getElementById('endTime').value
        let address = document.getElementById('address').value
        let city = document.getElementById('city').value
        let EULA = document.getElementById('DoYouAgree').checked
        let output = {
            'name': name, 'phone': phone, 'date': date,
            'startTime': startTime, 'endTime': endTime, 'time': Time,
            'location': address, 'city': city, 'EULA': EULA,
            'image': image
        }

        const requierd = ['name', 'phone', 'date', 'startTime', 'endTime', 'location', 'city']
        for (var i in requierd) {
            var item = requierd[i]
            var value = output[item]
            if (value === false || value === '') {
                console.log(item)
                const info = document.getElementById('info')
                info.style.color = 'red'
                info.style.fontWeight = '800'
                info.scrollIntoView({ behavior: 'smooth', block: 'center' })
                return
            }
        }

        if (EULA === false) {
            const dyat = document.getElementById('DoYouAgreeText')
            dyat.style.color = 'red'
            dyat.style.fontWeight = '800'
            dyat.scrollIntoView({ behavior: 'smooth', block: 'center' })
            return

        }

        if (Time === 0 || Time < 30) {
            const start = document.getElementById('startTime')
            const end = document.getElementById('endTime')
            start.style.borderColor = 'red'
            start.style.borderWidth = '1px'
            start.style.borderStyle = 'solid'
            end.style.borderColor = 'red'
            end.style.borderWidth = '1px'
            end.style.borderStyle = 'solid'
            end.scrollIntoView({ behavior: 'smooth', block: 'center' })
            return
        }

        if (getTimeInMinutes(startTime) > getTimeInMinutes(endTime)) {
            var a = startTime
            startTime = endTime
            endTime = a
        }

        function hasDatePassed(date) {
            const dateToCheck = new Date(date)
            const today = new Date()
            dateToCheck.setHours(0, getTimeInMinutes(startTime), 0, 0)
            return today > dateToCheck
        }

        if (hasDatePassed(date)) {
            document.getElementById('date').style.borderColor = 'red'
            document.getElementById('startTime').style.borderColor = 'red'
            document.getElementById('endTime').style.borderColor = 'red'
            return
        }

        if (image != undefined) {
            const base64String = image.split(',')[1]
            const byteString = atob(base64String)
            const fileSizeInKB = byteString.length / 1024

            //Its either this or the first algo that makes it so that you cant create a offer without image
            if (fileSizeInKB > 100) {
                document.getElementById('imgSelector').style.borderColor = 'red'
                return
            }
        }

        output = {
            'name': name, 'phone': phone, 'date': date,
            'startTime': startTime, 'endTime': endTime, 'time': Time,
            'location': address, 'city': city, 'EULA': EULA,
            'image': image
        }

        if (discount != undefined) {
            output.discount = discountCode
        }

        const response = await fetch("/api/create-offer", {
            method: "POST",
            body: JSON.stringify(output),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        });

        const data = await response.json()

        if (response.ok) {
            window.location.href = data.url
        } else {
            document.getElementById('info').style.color = 'red'
            document.getElementById('info').style.fontWeight = '800'
            return
        }
    }

    return (
        <div className="App">
            <Header />
            <Banner />
            <InfoBox success={success} message={infoBoxMessage} />
            <div style={{ display: 'block', textAlign: 'start' }}>
                <div style={{ display: 'inline-flex', minWidth: '320px', width: '75%', maxWidth: '500px' }}>
                    <div className='CreateOfferForm'>
                        <h1 style={{ color: 'black' }}>KUULUTUSE LOOMINE</h1>
                        <div className='body'>
                            <div className='item'>
                                <span>KOERA NIMI</span>
                                <input id="dogName" type="text" className='input' placeholder='Muhvin' />
                            </div>
                            <div className='item'>
                                <span>TELEFON</span>
                                <input id="phone" value={phone} onChange={phoneChange} type="nr" autoComplete='tel' placeholder='+372 5836 4426' />
                            </div>
                            <div className='item'>
                                <span>KUUPÄEV</span>
                                <input type='date' onClick={(e) => {
                                    if (!e.target.showPickerOpened) {
                                        e.target.showPicker()
                                        e.target.showPickerOpened = true
                                    } else {
                                        e.target.showPickerOpened = false
                                    }
                                }} onChange={(e) => {
                                    e.target.value = e.target.value
                                }} id='date' style={{ backgroundColor: 'white', width: '115px', paddingRight: '0px'}}></input>
                            </div>
                            <div className='item'>
                                <span >KELLAAEG</span>
                                <input className="timeSelect font12" style={{ backgroundColor: 'white', width: '72px', paddingRight: '0px', paddingLeft: '8px' }} onChange={timeChange} id='startTime' type="time" />
                                <span className="font18" style={{ marginLeft: '2px', marginRight: '2px'}}>-</span>
                                <input className="timeSelect font12" style={{ backgroundColor: 'white', width: '72px', paddingRight: '0px', paddingLeft: '8px' }} onChange={timeChange} id='endTime' type="time" />
                            </div>
                            <div className='item'>
                                <span>AADRESS</span>
                                <input id='address' value={address} onChange={(e) => { setAddress(e.target.value) }} type='address' placeholder='Suur-Kloostri tn 16' autoComplete="street-address" ></input>
                            </div>
                            <div className='item'>
                                <span>LINN/KÜLA</span>
                                <input id='city' value={city} onChange={(e) => { setCity(e.target.value) }} type='address' placeholder='Suur-Kloostri tn 16' autoComplete="address-level2" ></input>
                            </div>
                        </div>
                        <p id='info' className="font14" style={{ lineHeight: '20px', marginLeft: '20px', color: 'black' }}>
                            Enne kuulutuse loomist peate valge taustaga tabelid läbi lugema, sooritama ülekande, kõik lüngad ära täitma ja märkima kastikesed. Kõige lühem lubatud jalutusperiood on 30min.
                        </p>
                        <span>Pilt koerast (soovituslik):</span>
                        <ImgSelector image={image} setImage={setImage} maxSize={maxSize} />
                    </div>
                </div>
                <div style={{ display: 'inline-flex', minWidth: '320px', width: '75%', maxWidth: '500px' }}>
                    <div style={{ minWidth: '300px', width: '80%', maxWidth: '400px', margin: '0px auto 0px auto' }}>
                        <Prices time={Time} price={price} serviceFee={serviceFee} discount={discountAmount} total={total} startTime={startTime} endTime={endTime} />
                        <Discount setDiscountCode={setDiscountCode} errorMessage={discountError} discountData={discount} />
                    </div>
                </div>
            </div>
            <div style={{ minWidth: '320px', width: '75%', maxWidth: '500px', textAlign: 'center' }}>
                <button onClick={createOfferPost} style={{ margin: '10px', backgroundColor: '#247f24', width: '75%' }}>
                    LOO KUULUTUS
                </button>
            </div>
            <Footer />
        </div>
    );
}

export default CreateOffer;
