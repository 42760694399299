import '../css/App.css';
import Header from '../components/header';
import Banner from '../components/banner';
import React, { useState, useEffect } from 'react';
import Footer from '../components/footer'

function Admin({ title }) {

	document.title = title

	const [discounts, setDiscounts] = useState([])

	useEffect(() => {
		async function fetchData() {
			const response = await fetch("/api/allDiscounts", {
				method: "GET",
				headers: {
					"Content-type": "application/json; charset=UTF-8"
				}
			});

			var data = await response.json()

			var discs = data.discounts
			var result = []
			for (var key in discs) {
				discs[key].code = key
				result.push(discs[key])
			}

			setDiscounts(result)
		}
		fetchData()
	}, []);

	async function createDiscount(e){
		e.preventDefault()

		var amount = parseFloat(document.getElementById("discountAmount").value);
		var type = document.getElementById("discountType").options[document.getElementById("discountType").selectedIndex].value;
		var expiry = document.getElementById("discountExpiry").value;
		var minTime = parseInt(document.getElementById("minTime").value);

		if(type == '1'){
			amount *= 100
			type = 1
		}
		if(type == '0.01'){
			type = 0.01
			amount /= 100
		}

		const message = {'amount': amount, "type": type, "expiry": expiry, "required": minTime, 'used': false}

		const response = await fetch("/api/create-discount", {
            method: "POST",
            body: JSON.stringify(message),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        });

		if(response.ok){
			const data = await response.json()
			console.log('Well done')
			document.getElementById('discountForm').innerHTML = `<h1>code: ${data.code}</h1>`
		} else {
			console.log('Something went wrong')
			alert('Something went wrong')
		}
	}

	console.log(discounts)

	return (
		<div className="App">
			<Header />
			<Banner />

			<form id='discountForm' onSubmit={createDiscount} style={{display: 'flex', flexDirection: 'column', width: '600px', margin: '15px auto 15px auto', border: '2px solid black'}}>
				<h2>Create discount</h2>
				<span>
					<input id='discountAmount' type='number' step='0.01' required placeholder='amount'></input>
					<select id='discountType'>
						<option value={0.01}>%</option>
						<option value={1}>€</option>
					</select>
				</span>
				<input id='discountExpiry' type='date' required></input>
				<input id='minTime' type='number' required placeholder='requiredTimeMin'></input>
				<button type='submit' style={{color: 'black'}}>Create</button>
			</form>

			<div style={{ width: '600px', height: '1000px', overflowY: 'auto', margin: '15px auto 15px auto', borderColor: 'black', borderStyle: 'solid'}}>
				{discounts.map((discount, key) => (
					<div key={key} className='borderdDiv' style={{ width: '250px', height: '200px', margin: '5px 5px 10px 5px', backgroundColor: 'white', lineHeight: '1.2', display: 'inline-flex', flexDirection: 'column' }}>
						<h2 style={{ margin: '5px 0px 5px 0px', fontSize: '32px'}}>{discount.code}</h2>
						<span style={{ fontSize: '20px' }}>
							<h3 style={{ margin: '5px 5px 5px 0px', display: 'inline' }}>value:</h3>
							{discount.type == 1
								? <span>{discount.amount / 100}€</span>
								: <span>{discount.amount * 100}%</span>
							}
						</span>
						<span style={{ fontSize: '20px' }}>
							<h3 style={{ margin: '5px 5px 5px 0px', display: 'inline' }}>expiry:</h3><span>{discount.expiry}</span>
						</span>
						<span style={{ fontSize: '20px' }}>
							<h3 style={{ margin: '5px 5px 5px 0px', display: 'inline' }}>used:</h3>
							{discount.used == 1
								? <span>true ({discount.offer})</span>
								: <span>false</span>
							}
						</span>
						<span style={{ fontSize: '20px' }}>
							<h3 style={{ margin: '5px 5px 5px 0px', display: 'inline' }}>minTime:</h3><span>{discount.required}</span>
						</span>
					</div>
				))}
			</div>
			<Footer />
		</div>
	);
}

export default Admin;
