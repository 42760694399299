import { createRef, useState } from "react";
const reader = new FileReader();

function ImgSelector({image, setImage, maxSize}) {

    const input = createRef(null)
    const imgStyle = {maxWidth: '100%', maxHeight: '100%', objectFit: 'contain', margin: 'auto auto'}

    return (
        <div className="imgSelector" id='imgSelector' onClick={() => { input.current.click() }}>
            {image == undefined
                ? <img id="img" style={imgStyle} src={'https://icons.veryicon.com/png/o/application/designe-editing/add-image-1.png'} />
                : <img id="img" style={imgStyle} src={image} />
            }
            <input type="file" id="file" ref={input} accept="image/*" onChange={() => {
                try {
                    const f = input.current.files[0];
                    reader.readAsDataURL(f);
                    reader.onload = e => {
                        const img = new Image()
                        img.src = e.target.result
                        img.onload = () => {
                            const canvas = document.createElement('canvas')
                            const ctx = canvas.getContext('2d')

                            let width = img.width
                            let height = img.height

                            if (width > height){
                                if (width > maxSize) {
                                    height *= maxSize / width
                                    width = maxSize
                                }
                            } else {
                                if (height > maxSize) { 
                                    width *= maxSize / height
                                    height = maxSize
                                }
                            }

                            canvas.width = width
                            canvas.height = height
                            ctx.drawImage(img, 0, 0, width, height)

                            const scaledImage = canvas.toDataURL('image/jpeg')

                            const base64String = scaledImage.split(',')[1]
                            const byteString = atob(base64String)
                            const fileSizeInKB = byteString.length / 1024


                            var imgSelectorItem = document.getElementById('imgSelector')
                            if (fileSizeInKB > 100){
                                imgSelectorItem.style.borderColor = 'red'
                            } else {
                                imgSelectorItem.style.borderColor = 'black'
                            }

                            setImage(scaledImage)
                        }
                    }
                }
                catch{
                    console.error('Something went wrong with the image')
                }
            }} />
        </div>
    );
}

export default ImgSelector;
