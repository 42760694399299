import '../css/App.css';
import Header from '../components/header';
import Banner from '../components/banner';
import Footer from '../components/footer'
import UserInfo from '../components/userInfo'
import { useState, useEffect } from 'react';
import OfferList from '../components/offerList'

function Profile({title}) {

    document.title = title

    const [userData, setUserData] = useState(NaN);
    const [activeOffers, setActiveOffers] = useState([]);
    const [unactiveOffers, setUnactiveOffers] = useState([]);

    const cancelButton = {
        'style': { backgroundColor: '#b23b3b', minWidth: '70px', width: '90%'},
        'text': 'Tühista',
        'function': async (e) => {
            fetch(`/api/offer?id=${e.target.value}`, {
                method: "DELETE",
                headers: {
                  "Content-type": "application/json; charset=UTF-8"
                }
            });
            
            window.location.replace("/Profile");
        }
    }
    

    useEffect(() => {
        async function fetchOffersData(offers) {
            var result = {'active': [], 'unactive': []}
            for(var offer in offers){
                const response = await fetch(`/api/offer?id=${offers[offer]}`, {
                    method: "GET",
                    headers: {
                      "Content-type": "application/json; charset=UTF-8"
                    }
                });

                if (!response.ok){
                    continue
                }

                var message = await response.json()

                if(message.transactionStatus == 'pending'){
                    continue
                }

                if(message.active){
                    result['active'].push(message)
                }
                else if(!message.active){
                    result['unactive'].push(message)
                }
            }

            setActiveOffers(result['active'])
            setUnactiveOffers(result['unactive'])
            return result
        }

        async function fetchUserData() {
            const response = await fetch("/api/userInfo", {
                method: "GET",
                headers: {
                  "Content-type": "application/json; charset=UTF-8"
                }
            });
            var data = await response.json()
            setUserData(data)
            console.log(data.offers)
            await fetchOffersData(data.offers)
        }
        fetchUserData();
    }, []);

    if(userData.phone == undefined || userData.phone == ''){
        return(
            <div className="App">
                <Banner />
                <UserInfo userData={userData}/>
            </div>
            
        )
    }

	return (
		<div className="App">
            <Header />
            <Banner />
            <UserInfo userData={userData}/>
            <div style={{ display: 'block'}}>
                <div style={{display: 'inline-flex', minWidth: '300px', width: '75%', maxWidth: '500px', height: '750px', margin: '15px 10px 15px 10px'}}>
                    <OfferList offers={activeOffers} title={'Aktiivsed kuulutused'} button={cancelButton}/>
                </div>
                <div style={{display: 'inline-flex', minWidth: '300px', width: '75%', maxWidth: '500px', height: '750px', margin: '15px 10px 15px 10px'}}>
                    <OfferList offers={unactiveOffers} title={'Vanad kuulutused'}/>
                </div>
            </div>
            <Footer />
    	</div>
	);
}

export default Profile;
