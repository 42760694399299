import { useState } from "react";

function Discount({ setDiscountCode, errorMessage, discountData }) {

	const [discount, setDiscount] = useState('')

	function discountChange(e) {

		let s = e.target.value.toString()
		s = s.toUpperCase()
		if (s.length > 11) {
			setDiscount(s.slice(0, 11))
			return
		}
		var regex = /[0-9A-Z]/g;
		var match = s.match(regex)
		if (match != null && match.length > 0) {
			s = match.join('')
			regex = /[0-9A-Z]{1,3}/g
			match = s.match(regex)
			if (match != null && match.length > 0) s = match.join('-')
		}
		else {
			s = ''
		}
		setDiscount(s)
	}

	if (discountData == undefined) {
		return (
			<div className="discount">
				<h1 className="font18" style={{ margin: '10px 10px 0px 10px' }}>Kinkekaart/Soodus</h1>
				<h2 className="font14" style={{ color: "red", margin: '0 0 0 10px' }}>{errorMessage}</h2>
				<div className="container">
					<input placeholder="XXX-XXX-XXX" className="betterInput" onChange={discountChange} value={discount} />
					<button onClick={(e) => {
						e.preventDefault()
						setDiscountCode(discount)
					}}>Lisa</button>
				</div>
			</div>
		);
	}
	return <div className="discount">
		<h1 className="font18" style={{ margin: '10px 10px 0px 10px' }}>Kinkekaart/Soodus</h1>
		<h2 className="font14" style={{ color: "red", margin: '0 0 0 10px' }}>{errorMessage}</h2>
		<div className="container">
			<div className="discountShowcase">
				<span>{discount}</span>
				<span className="type">
					{discountData.type == 1
					?<span><span>-{discountData.amount/100}</span>
						<span >€</span></span>
					:<span><span>-{discountData.amount*100}</span>
						<span >%</span></span>
					}
				</span>

			</div>
			<button onClick={(e) => {
				e.preventDefault()
				setDiscountCode('')
			}}>Eemalda</button>
		</div>
	</div>
}

export default Discount;
